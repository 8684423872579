import React, { useEffect, useState } from "react";
import {
	Icon,
	IconChevron,
	IconChevronWithLine,
	Spinner,
	Button,
	InlineText,
	Content,
	Divider,
	Tooltip,
	useStaticMode,
	cn,
} from "@siteimprove/fancylib";
import { Popover } from "../../overlay/popover/popover";
import { InputField } from "../../forms-and-inputs/input-field/input-field";
import { FormElementWrapper } from "../../forms-and-inputs/form-element-wrapper/form-element-wrapper";
import { ActionBar } from "../../actions-and-controls/action-bar/action-bar";
import { KeyCode } from "../../../utils/keyboard-nav-utils";
import { Radios } from "../../forms-and-inputs/radios/radios";
import { Form } from "../../forms-and-inputs/form/form";

import * as scss from "./pagination.scss";

export interface PaginationProps {
	/** Total count of items */
	total: number;
	/** Selected page number */
	page: number;
	/** Callback to change the selected page */
	setPage: (page: number) => void;
	/** Selected page size */
	pageSize: number;
	/** Callback to change the selected page size */
	setPageSize: ((pageSize: number) => void) | null;
	/** Label for the cancel button */
	cancelLabel: string;
	/** Label for the confirm button */
	confirmLabel: string;
	/** Tooltip text for the first button */
	firstLabel: string;
	/** Tooltip text for the previous button */
	prevLabel: string;
	/** Tooltip text for the next button */
	nextLabel: string;
	/** Tooltip text for the last button */
	lastLabel: string;
	/** Label for paging info - something like `${startIdx} - ${endIdx} of ${total} items` */
	pagingInfoLabel: (startIdx: number, endIdx: number, total: number) => string;
	/** Label for page input */
	pageLabel: string;
	/** Label for page dropdown button - something like `Page ${current} of ${total} */
	pageXofYLabel: (current: number, total: number) => string;
	/** Label for pageSize dropdown button - something like `${pageSize} items` */
	pageSizeSelectionLabel: (pageSize: number) => string;
	/** Label before pageSize changer - Show (<- this part) DROPDOWN per page */
	pageSizeSelectorPrefix: string;
	/** Label after pageSize changer - Show DROPDOWN per page (<- this part) */
	pageSizeSelectorPostfix: string;
	/** Label for pageSize radios - something like "Items per page" */
	pageSizeLabel: string;
	/** Default error for page number input */
	defaultError: string;
	/** Error for page number input when number is not integer */
	wholeNumberError: string;
	/** Error for page number input when number is out of bounds (below 1 and above total) */
	outOfBoundsError: (total: number) => string;
	/** Count of items */
	count?: number;
	/** Loading state */
	loading?: boolean;
	/** Used as data observe key for pagination buttons */
	observeKeyPrefix?: string;
	/** Text for the ARIA label */
	ariaLabel?: string;
}

interface PageChangerDropdownProps {
	/** Currently selected page */
	currentPage: number;
	/** Total number of pages */
	totalPages: number;
	/** Callback for onChange event */
	onChange: (page: number) => void;
	/** Label for the cancel button */
	confirmLabel: string;
	/** Label for the confirm button */
	cancelLabel: string;
	/** Label for page input */
	pageLabel: string;
	/** Label for page dropdown button - something like `Page ${current} of ${total} */
	pageXofYLabel: (current: number, total: number) => string;
	/** Default error for page number input */
	defaultError: string;
	/** Error for page number input when number is not integer */
	wholeNumberError: string;
	/** Error for page number input when number is out of bounds (below 1 and above total) */
	outOfBoundsError: (total: number) => string;
	/** Disabled state */
	disabled?: boolean;
	/** Used as data observe key for pagination buttons */
	observeKeyPrefix?: string;
}

interface PageSizeDropdownProps {
	/** Current page size */
	currentPageSize: number;
	/** Callback for onChange event */
	onChange: (size: number) => void;
	/** Label for the cancel button */
	confirmLabel: string;
	/** Label for the confirm button */
	cancelLabel: string;
	/** Label for pageSize radios - something like "Items per page" */
	pageSizeLabel: string;
	/** Label for pageSize dropdown button - something like `${pageSize} items` */
	pageSizeSelectionLabel: (pageSize: number) => string;
	/** Label before pageSize changer - Show (<- this part) DROPDOWN per page */
	pageSizeSelectorPrefix: string;
	/** Label after pageSize changer - Show DROPDOWN per page (<- this part) */
	pageSizeSelectorPostfix: string;
	/** Used as data observe key for pagination buttons */
	observeKeyPrefix?: string;
}

export function Pagination(props: PaginationProps): JSX.Element {
	const {
		setPage,
		pageSize,
		setPageSize,
		loading,
		total,
		confirmLabel,
		cancelLabel,
		firstLabel,
		prevLabel,
		nextLabel,
		lastLabel,
		pageLabel,
		pageXofYLabel,
		pageSizeLabel,
		pageSizeSelectionLabel,
		defaultError,
		wholeNumberError,
		outOfBoundsError,
		pageSizeSelectorPrefix,
		pageSizeSelectorPostfix,
		pagingInfoLabel,
		observeKeyPrefix,
		ariaLabel,
	} = props;
	const totalPages = total > 0 && pageSize > 0 ? Math.ceil(total / pageSize) : 0;
	//Ensure page is lower than totalPages and higher than 0
	let page = Math.min(props.page, totalPages);
	page = Math.max(0, props.page);

	const showingStartItemIndex = 1 + (page - 1) * pageSize;
	const showingEndItemIndex = Math.min(showingStartItemIndex + pageSize - 1, total);

	const pagingInfoText = (
		<InlineText>
			<div
				aria-live="polite"
				aria-atomic="true"
				className={cn(scss.option)}
				dangerouslySetInnerHTML={{
					__html: pagingInfoLabel(showingStartItemIndex, showingEndItemIndex, total),
				}}
			/>
		</InlineText>
	);

	const isStatic = useStaticMode();

	return (
		<div
			data-component="pagination"
			role="group"
			aria-label={ariaLabel}
			className={cn(scss.pagination, "fancy-Pagination")}
		>
			{isStatic ? (
				pagingInfoText
			) : (
				<>
					{/* Loading spinner */}
					<div className={cn(scss.loading, !loading && scss.hidden)}>
						<Spinner variant="dark" />
					</div>

					{/* Paging info text */}
					{pagingInfoText}

					{pageChanger()}

					{/* Page size dropdown */}
					{setPageSize ? (
						<div className={cn(scss.option, scss.optionPageSize)}>
							<InlineText>
								<span aria-hidden="true">{pageSizeSelectorPrefix}</span>
							</InlineText>
							<PageSizeDropdown
								currentPageSize={pageSize}
								onChange={setPageSize}
								cancelLabel={cancelLabel}
								confirmLabel={confirmLabel}
								pageSizeLabel={pageSizeLabel}
								pageSizeSelectionLabel={pageSizeSelectionLabel}
								pageSizeSelectorPrefix={pageSizeSelectorPrefix}
								pageSizeSelectorPostfix={pageSizeSelectorPostfix}
								observeKeyPrefix={observeKeyPrefix}
							/>
							<InlineText>
								<span aria-hidden="true">{pageSizeSelectorPostfix}</span>
							</InlineText>
						</div>
					) : (
						<div className={cn(scss.option, scss.optionPageSize)}></div>
					)}
				</>
			)}
		</div>
	);

	function pageChanger() {
		if (page == 1 && pageSize >= total) {
			// We're on the first page and there are no more pages to choose from.
			// Hide the page changer.
			return <></>;
		}

		return (
			<div className={cn(scss.option, scss.optionPageChange)}>
				<Button.Group>
					<Tooltip variant={{ type: "interactive" }} content={firstLabel}>
						<Button
							aria-label={firstLabel}
							disabled={page === 1}
							onClick={() => setPage(1)}
							data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-button-to-first-page`}
						>
							<Icon rotation="0">
								<IconChevronWithLine />
							</Icon>
						</Button>
					</Tooltip>
					<Tooltip variant={{ type: "interactive" }} content={prevLabel}>
						<Button
							aria-label={prevLabel}
							disabled={page === 1}
							onClick={() => setPage(Math.max(1, page - 1))}
							data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-button-to-previous-page`}
						>
							<Icon rotation="90">
								<IconChevron />
							</Icon>
						</Button>
					</Tooltip>
				</Button.Group>
				<PageChangerDropdown
					currentPage={page}
					totalPages={totalPages}
					onChange={setPage}
					cancelLabel={cancelLabel}
					confirmLabel={confirmLabel}
					pageLabel={pageLabel}
					pageXofYLabel={pageXofYLabel}
					defaultError={defaultError}
					wholeNumberError={wholeNumberError}
					outOfBoundsError={outOfBoundsError}
					observeKeyPrefix={observeKeyPrefix}
				/>
				<Button.Group>
					<Tooltip variant={{ type: "interactive" }} content={nextLabel}>
						<Button
							aria-label={nextLabel}
							disabled={page === totalPages}
							onClick={() => setPage(Math.min(page + 1, totalPages))}
							data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-button-to-next-page`}
						>
							<Icon rotation="270">
								<IconChevron />
							</Icon>
						</Button>
					</Tooltip>
					<Tooltip variant={{ type: "interactive" }} content={lastLabel}>
						<Button
							aria-label={lastLabel}
							disabled={page === totalPages}
							onClick={() => setPage(totalPages)}
							data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-button-to-last-page`}
						>
							<Icon rotation="180">
								<IconChevronWithLine />
							</Icon>
						</Button>
					</Tooltip>
				</Button.Group>
			</div>
		);
	}
}

function PageSizeDropdown(props: PageSizeDropdownProps) {
	const {
		currentPageSize,
		onChange,
		cancelLabel,
		confirmLabel,
		pageSizeLabel,
		pageSizeSelectionLabel: label,
		pageSizeSelectorPrefix,
		pageSizeSelectorPostfix,
		observeKeyPrefix,
	} = props;

	const options = [5, 10, 20, 30, 40, 50, 100, 200];
	const [size, setSize] = useState(currentPageSize);

	useEffect(() => {
		setSize(currentPageSize);
	}, [currentPageSize]);

	return (
		<Popover
			data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-page-size-dropdown`}
			placement="auto-end"
			allowedAutoPlacements={["top-end", "bottom-end"]}
			aria-label={`${pageSizeSelectorPrefix} ${label(currentPageSize)} ${pageSizeSelectorPostfix}`}
			popoverContent={(id, ref, { setIsOpen }) => (
				<div id={id}>
					<Content>
						<Form>
							<FormElementWrapper label={pageSizeLabel} name="page-size">
								<Radios value={String(size)} onChange={(val) => setSize(parseInt(val))}>
									{options.map((x, i) => (
										<Radios.Radio key={String(x)} value={String(x)} ref={i === 0 ? ref : undefined}>
											{label(x)}
										</Radios.Radio>
									))}
								</Radios>
							</FormElementWrapper>
						</Form>
					</Content>
					<Divider />
					<ActionBar
						primary={{
							children: confirmLabel,
							onClick: () => {
								setIsOpen(false);
								onChange(size);
							},
							"data-observe-key":
								observeKeyPrefix && `${observeKeyPrefix}-edit-page-size-confirm-button`,
						}}
						cancel={{
							children: cancelLabel,
							onClick: () => {
								setIsOpen(false);
								setSize(currentPageSize);
							},
							"data-observe-key":
								observeKeyPrefix && `${observeKeyPrefix}-edit-page-size-cancel-button`,
						}}
					/>
				</div>
			)}
			buttonContent={
				<InlineText>
					<span aria-hidden="true">{label(currentPageSize)}</span>
				</InlineText>
			}
		/>
	);
}

function PageChangerDropdown(props: PageChangerDropdownProps) {
	const {
		currentPage,
		onChange,
		totalPages,
		disabled,
		confirmLabel,
		cancelLabel,
		pageLabel,
		pageXofYLabel,
		defaultError,
		wholeNumberError,
		outOfBoundsError,
		observeKeyPrefix,
	} = props;

	const [inputVal, setInputVal] = useState(`${currentPage}`);

	const inputValid = isInteger(inputVal) && isWithinMinMax(inputVal);

	useEffect(() => {
		setInputVal(`${currentPage}`);
	}, [currentPage]);

	return (
		<Popover
			data-observe-key={observeKeyPrefix && `${observeKeyPrefix}-page-number-dropdown`}
			popoverContent={(id, ref, { setIsOpen }) => (
				<div id={id}>
					<Content>
						<Form>
							<FormElementWrapper
								label={pageLabel}
								name="page"
								invalid={!inputValid}
								error={errorMessage(inputVal)}
							>
								<InputField
									ref={ref}
									aria-label={pageLabel}
									value={inputVal}
									onChange={setInputVal}
									onKeyDown={(e) => {
										if (inputValid && e.keyCode === KeyCode.Enter) {
											e.preventDefault();
											confirm(setIsOpen);
										}
									}}
								/>
							</FormElementWrapper>
						</Form>
					</Content>
					<Divider />
					<ActionBar
						primary={{
							children: confirmLabel,
							onClick: () => confirm(setIsOpen),
							disabled: !inputValid,
							"data-observe-key":
								observeKeyPrefix && `${observeKeyPrefix}-edit-page-number-confirm-button`,
						}}
						cancel={{
							children: cancelLabel,
							onClick: () => {
								setIsOpen(false);
								setInputVal(`${currentPage}`);
							},
							"data-observe-key":
								observeKeyPrefix && `${observeKeyPrefix}-edit-page-number-cancel-button`,
						}}
					/>
				</div>
			)}
			buttonContent={pageXofYLabel(currentPage, totalPages)}
			buttonProps={{ disabled }}
		/>
	);

	function confirm(setOpen: (x: boolean) => void) {
		onChange(+inputVal);
		setOpen(false);
	}

	function isInteger(val: string) {
		return val.match(/^\d+$/);
	}

	function isWithinMinMax(val: string) {
		if (!isInteger(val)) {
			return false;
		}

		return +val >= 1 && +val <= totalPages;
	}

	function errorMessage(val: string) {
		if (!isInteger(val)) {
			return wholeNumberError;
		}

		if (!isWithinMinMax(val)) {
			return outOfBoundsError(totalPages);
		}

		return defaultError;
	}
}
